import React from 'react';
import PrincipalsDesk from './PrincipalsDesk.js';

const offerData = [
  {
    title: 'Qualified Teachers',
    description: 'Our teachers are highly qualified with extensive experience in their respective fields. They are dedicated to providing quality education and personalized attention to each student.',
  },
  {
    title: 'Modern Facilities',
    description: 'We offer state-of-the-art facilities including smart classrooms, science labs, and a well-stocked library to enhance the learning experience of our students.',
  },
  {
    title: 'Extracurricular Activities',
    description: 'From sports and arts to robotics and debate clubs, our school provides a wide range of extracurricular activities to help students explore their interests and talents.',
  },
  {
    title: 'Student Support Services',
    description: 'We have a robust support system in place, including counseling services, academic assistance, and special education programs to ensure every student’s needs are met.',
  },
  {
    title: 'Safe and Nurturing Environment',
    description: 'Our school prioritizes the safety and well-being of our students, offering a secure and nurturing environment where they can thrive academically and socially.',
  },
];




const OfferSection = () => {
  return (
    <section className="offer_section hero_next_section-margin layout_padding">
    <PrincipalsDesk/>
      <div className="container">
        <div className="heading_container">
          <h2>what we offer</h2>
          <p>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem
          </p>
        </div>
        <div className="row">
          {offerData.map((item, index) => (
            <div className="col-md-6" key={index}>
              <div className="content-box">
               
                <div className="detail-box">
                  <h6> <span className="icon-box mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 30 30">
                    <circle cx="12" cy="12" r="12" fill="black" />
                    <path d="M9 16.2l-4.2-4.2 1.4-1.4 2.8 2.8L19.8 5.4l1.4 1.4z" fill="white" />
                  </svg>
                </span>{item.title}</h6>
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OfferSection;
