import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import principal_pic from '../../images/school_principal.jpg';

Modal.setAppElement('#root'); // Set the app element to prevent accessibility issues

const PrincipalDesk = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState('');

  const notices = [
    "Notice 1: School will remain closed on Friday.",
    "Notice 2: Annual sports day is on September 10th.",
    "Notice 3: Parent-teacher meeting on September 15th.",
    "Notice 4: Science exhibition on September 20th.",
    "Notice 5: Winter break starts on December 24th.",
    "Notice 6: School reopening on January 5th.",
    "Notice 7: Mid-term exams from October 1st to October 10th.",
    "Notice 8: Annual cultural fest on November 5th.",
    "Notice 9: New academic session begins on April 1st.",
    "Notice 10: School library will be open on Saturdays.",
    "Notice 11: Art competition on September 30th.",
    "Notice 12: School uniform sale on September 25th.",
    "Notice 13: No school bus service on October 5th.",
    "Notice 14: Submit project reports by September 28th.",
    "Notice 15: Blood donation camp on October 15th.",
    "Notice 16: Yoga classes every Wednesday morning.",
    "Notice 17: Extra classes for grade 10 students.",
    "Notice 18: School picnic on November 15th.",
    "Notice 19: Parent volunteers needed for sports day.",
    "Notice 20: Health checkup camp on September 29th.",
  ];

  useEffect(() => {
    let scrollInterval;
    if (!isHovered) {
      scrollInterval = setInterval(() => {
        const board = document.getElementById('noticeBoard');
        if (board.scrollTop < board.scrollHeight - board.clientHeight) {
          board.scrollTop += 1;
        } else {
          board.scrollTop = 0; // Restart the scroll
        }
      }, 50); // Adjust speed as needed
    }

    return () => {
      clearInterval(scrollInterval);
    };
  }, [isHovered]);

  const openModal = (notice) => {
    setSelectedNotice(notice);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="container mb-5">
       <div className="heading_container">
          <h2>Principal's Desk</h2>
          <p className='pb-4'>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem
          </p>
        </div>
      <div className="principal-desk">
        <div style={{ width: '100%', maxWidth: '400px', flex: '1 1 auto' }}>
          <img
            src={principal_pic} // Replace with actual image path
            alt="Principal"
          />
          <h2>Shreya Sengupta</h2>
          <p>M.Sc PhD in Mathematics</p>

        </div>

        <div
          id="noticeBoard"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div>
            {notices.map((notice, index) => (
              <p
                key={index}
                onClick={() => openModal(notice)}
              >
                {notice}
              </p>
            ))}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Notice Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Notice Details</h2>
        <p>{selectedNotice}</p>
        <button onClick={closeModal} style={{ marginTop: '20px' }}>Close</button>
      </Modal>
    </div>
  );
};

export default PrincipalDesk;
