import React from 'react';
// import FreeToolBoxComponent from '../components/Banner';

// import TopTools from '../components/Toolbox/TopTools';
// import { Link } from 'react-router-dom';

import CarouselComponent from '../components/template/Slider';
import Offer from '../components/template/Offer';
import AboutSection from '../components/template/About';
import ClientFeedback from '../components/template/ParentsFeedback';
import ContactSection from '../components/template/ContactForm';


const Home = () => {
  return (
    <>
      
       <CarouselComponent/>
       <Offer/>
       <AboutSection/>
       <ClientFeedback/>
       <ContactSection/>

    </>
  );
};

export default Home;
