import React from "react";

const CarouselComponent = () => {
  const carouselItems = [
    {
      title: "A Perfect Learning Center",
      subtitle: "For Your Kids",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as",
      btnReadMore: "Read More",
      btnContactUs: "Contact us",
    },
    {
      title: "A Perfect Learning Center",
      subtitle: "For Your Kids",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as",
      btnReadMore: "Read More",
      btnContactUs: "Contact us",
    },
    {
      title: "A Perfect Learning Center",
      subtitle: "For Your Kids",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as",
      btnReadMore: "Read More",
      btnContactUs: "Contact us",
    },
    {
      title: "A Perfect Learning Center",
      subtitle: "For Your Kids",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as",
      btnReadMore: "Read More",
      btnContactUs: "Contact us",
    },
    {
      title: "A Perfect Learning Center",
      subtitle: "For Your Kids",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as",
      btnReadMore: "Read More",
      btnContactUs: "Contact us",
    },
  ];

  return (
    <section className="slider_section position-relative">
      <div className="container">
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            {carouselItems.map((_, index) => (
              <li
                key={index}
                data-target="#carouselExampleIndicators"
                data-slide-to={index}
                className={index === 0 ? "active" : ""}
              ></li>
            ))}
          </ol>
          <div className="carousel-inner">
            {carouselItems.map((item, index) => (
              <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="detail-box">
                      <h1>
                        {item.title} <br />
                        <span>{item.subtitle}</span>
                      </h1>
                      <p>{item.description}</p>
                      <div className="btn-box">
                        <a href="/" className="btn-1">
                          {item.btnReadMore}
                        </a>
                        <a href="/" className="btn-2">
                          {item.btnContactUs}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarouselComponent;
