import React from 'react';

const ContactSection = () => {
  // Replace with your actual Google Maps embed iframe code
  const googleMapEmbedUrl = `
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1848.158252371178!2d87.79978112515873!3d22.113905049907913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02c341cc5b2073%3A0x5aaf97b8b9182409!2sGopinathpur%20high%20school(H.S.)!5e0!3m2!1sen!2sin!4v1724738765011!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  `;

  return (
    <section className="contact_section layout_padding">
      <div className="container">
        <div className="heading_container">
          <h2>
            Request
            <span> A call Back</span>
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={(event) => {
              event.preventDefault();
              // Handle form submission here
              console.log('Form submitted');
            }}>
              <div>
                <input type="text" placeholder="Name" required />
              </div>
              <div>
                <input type="email" placeholder="Email" required />
              </div>
              <div>
                <input type="text" placeholder="Phone Number" required />
              </div>
              <div>
                <input type="text" className="message-box" placeholder="Message" required />
              </div>
              <div className="d-flex mt-4">
                <button type="submit">SEND</button>
              </div>
            </form>
          </div>
          <div className="col-md-6">
            {/* Map section */}
            <div className="map_section">
              <div
                dangerouslySetInnerHTML={{ __html: googleMapEmbedUrl }}
                className="map_iframe"
              />
            </div>
            {/* End map section */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
